import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  Inject,
  FormDesigner,
  ToolbarItem,
  AnnotationToolbarItem,
  ContextMenuItem,
  CustomStampSettings,
  AnnotationAddEventArgs,
  PageChangeEventArgs,
  AnnotationSelectEventArgs,
  PageClickEventArgs,
  AnnotationRemoveEventArgs,
} from "@syncfusion/ej2-react-pdfviewer";
import jsQR from "jsqr";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPdfFileURI,
  AppStatus,
  setStatus,
  setCurrentPage,
  setDisplayNavigator,
} from "features/appStateSlice";
import { RootState } from "services/store";
import {
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
} from "@syncfusion/ej2-react-navigations";
import "./SyncfusionViewer.css";
import { readFile } from "utils/ReadFile";
import { PDFDocument } from "pdf-lib";
import api from "services/api";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { usePdfViewer } from "context/PdfViewerContext";
import { useDialog } from "context/DialogContext";
import { IBarcodeListData } from "models/IBarcodeListData";
import BottomSheetDialog from "components/tools/BottomSheetDialog/BottomSheetDialog"; // Import the BottomSheetDialog component
import RightSheetDialog from "components/tools/RightSheetDialog/RightSheetDialog"; // Import the BottomSheetDialog component
import { updateAnnotationIdInDB } from "services/indexeddb";
import DragAndDropFileImport from "components/forms/DragAndDropFileImport/DragAndDropFileImport";
import { useSnackbar } from "context/SnackbarContext";
import PdfStatusChip from "components/tools/PdfStatusChips/PdfStatusChips";
import { checkField, initFields } from "features/doctypesSlice";

const SyncfusionViewer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateAnnotationStatus, goToPage } = usePdfViewer();
  const { forceCloseDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const username = useSelector((state: RootState) => state.user.username);
  const status = useSelector((state: RootState) => state.appState.status);
  const pdfFileURI = useSelector(
    (state: RootState) => state.appState.pdfFileURI
  );
  const selectedDoctypeKey = useSelector(
    (state: RootState) => state.doctypes.selectedDoctypeKey
  );
  const doctype = useSelector((state: RootState) =>
    state.doctypes.doctypes.find((doc) => doc.Key === selectedDoctypeKey)
  );
  const [displayTool, setDisplayTool] = useState(false);

  const { pdfViewer, setPdfViewer, addAnnotation } = usePdfViewer();
  const toolbarRef = useRef<ToolbarComponent | null>(null);
  const totalPageRef = useRef<HTMLSpanElement>(null);
  const currentPageRef = useRef<HTMLInputElement>(null);
  const firstPageRef = useRef<any>(null);
  const previousPageRef = useRef<any>(null);
  const nextPageRef = useRef<any>(null);
  const lastPageRef = useRef<any>(null);
  const inputImageRef = useRef<HTMLInputElement>(null);

  const currentPage = useSelector(
    (state: RootState) => state.appState.currentPage
  );

  const [isSearchBox, setIsSearchBox] = useState<boolean>(true);
  const [isAnnotationToolbarVisible, setIsAnnotationToolbarVisible] =
    useState<boolean>(true);
  let currentPageNumber = "1";
  let image: HTMLImageElement | null = null;

  const toolbarItems: ToolbarItem[] = [
    "PrintOption",
    "SearchOption",
    "PageNavigationTool",
    "MagnificationTool",
    "SelectionTool",
    "AnnotationEditTool",
    "DownloadOption",
  ];
  const annotationToolbarItems: AnnotationToolbarItem[] = [
    "HighlightTool",
    "UnderlineTool",
    "StrikethroughTool",
    "ColorEditTool",
    "StrokeColorEditTool",
    "AnnotationDeleteTool",
    "HandWrittenSignatureTool",
    "InkAnnotationTool",
    "ThicknessEditTool",
    "FreeTextAnnotationTool",
    "FontFamilyAnnotationTool",
    "FontSizeAnnotationTool",
    "FontStylesAnnotationTool",
    "FontAlignAnnotationTool",
    "FontColorAnnotationTool",
  ];

  useEffect(() => {}, [currentPageRef, currentPageRef.current]);

  useEffect(() => {
    if (pdfViewer) {
      const filename = pdfFileURI.split("/").pop();
      if (filename) {
        if (filename.endsWith(".pdf")) {
          pdfViewer.downloadFileName = filename;
        } else if (filename.endsWith(".pdflock")) {
          pdfViewer.downloadFileName = filename.replace(".pdflock", ".pdf");
        }
      }
    }
  }, [pdfFileURI, pdfViewer]);

  const onDocumentLoaded = async (args: any) => {
    forceCloseDialog();
    if (!pdfViewer) return;

    if (totalPageRef.current) {
      totalPageRef.current.textContent = "/ " + pdfViewer.pageCount;
    }

    dispatch(setStatus(AppStatus.LOADED));

    dispatch(initFields());

    updateAnnotationStatus();

    if (pdfViewer) {
      pdfViewer.updateViewerContainer();
    }
    
    pdfViewer.magnification.fitToPage();
  };

  const onDocumentUnloaded = async (args: any) => {};

  const onAnnotationAdded = async (args: AnnotationAddEventArgs) => {
    if (!pdfViewer) return;

    dispatch(setStatus(AppStatus.EDITING));
    updateAnnotationStatus();
    refreshPageRefNumber();
  };

  const onAnnotationRemoved = (args: AnnotationRemoveEventArgs) => {
    if (!pdfViewer) return;

    showSnackbar({
      message: t("barcodeRemoved", { pageNumber: args.pageIndex + 1 }),
      severity: "success",
      duration: 3000,
    });

    dispatch(setStatus(AppStatus.EDITING));

    if (currentPage === args.pageIndex + 1) {
      dispatch(setCurrentPage(null));
    }

    goToPage(args.pageIndex + 1);

    updateAnnotationStatus();
    refreshPageRefNumber();

    if (pdfViewer) {
      pdfViewer.updateViewerContainer();
    }
  };

  const signatureAdded = (args: any) => {
    dispatch(setStatus(AppStatus.EDITING));
  };

  const currentPageClicked = () => {
    if (currentPageRef.current) {
      currentPageRef.current.select();
    }
  };

  const onCurrentPageBoxKeypress = (event: any) => {
    if (!pdfViewer) return;
    if (currentPageRef.current) {
      if (
        (event.which < 48 || event.which > 57) &&
        event.which !== 8 &&
        event.which !== 13
      ) {
        event.preventDefault();
        return false;
      } else {
        let currentPageNumber = parseInt(currentPageRef.current.value);
        if (event.which === 13) {
          currentPageNumber = Math.min(
            Math.max(currentPageNumber, 1),
            pdfViewer.pageCount
          );
          pdfViewer.navigation.goToPage(currentPageNumber);
        }
        return true;
      }
    }
  };

  useEffect(() => {
    if (currentPageRef.current) {
      if (!currentPageRef?.current?.value) {
        refreshPageRefNumber();
      }
    }
  }, [currentPageRef, currentPageRef.current, currentPageRef?.current?.value]);

  const refreshPageRefNumber = () => {
    if (!pdfViewer) return;
    currentPageNumber = pdfViewer.currentPageNumber.toString();
    if (currentPageRef.current) {
      currentPageRef.current.value = currentPageNumber;
    }
  };

  const onPageChange = (args: PageChangeEventArgs) => {
    if (!pdfViewer) return;
    currentPageNumber = pdfViewer.currentPageNumber.toString();
    if (currentPageRef.current) {
      refreshPageRefNumber();
    }
    if (pdfViewer.currentPageNumber !== currentPage) {
      dispatch(setCurrentPage(null));
    }
  };

  const clickHandler = (args: any) => {
    if (!pdfViewer) return;
    switch (args.item.id) {
      case "first_page":
        pdfViewer.navigation.goToFirstPage();
        break;
      case "previous_page":
        pdfViewer.navigation.goToPreviousPage();
        break;
      case "next_page":
        pdfViewer.navigation.goToNextPage();
        break;
      case "last_page":
        pdfViewer.navigation.goToLastPage();
        break;
      case "print":
        pdfViewer.print.print();
        break;
      case "download":
        pdfViewer.download();
        break;
      case "fit_to_page":
        pdfViewer.magnification.fitToPage();
        break;
      case "zoom_in":
        pdfViewer.magnification.zoomIn();
        break;
      case "zoom_out":
        pdfViewer.magnification.zoomOut();
        break;
      case "image":
        if (inputImageRef.current) inputImageRef.current.click();
        break;
      case "annotation":
        if (isAnnotationToolbarVisible) {
          pdfViewer.toolbar.showAnnotationToolbar(true);
          setIsAnnotationToolbarVisible(false);
        } else {
          pdfViewer.toolbar.showAnnotationToolbar(false);
          setIsAnnotationToolbarVisible(true);
        }
        break;
      case "search":
        if (isSearchBox) {
          pdfViewer.textSearch.showSearchBox(true);
          setIsSearchBox(false);
        } else {
          pdfViewer.textSearch.showSearchBox(false);
          setIsSearchBox(true);
        }
        break;
    }
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!pdfViewer) return;
    if (e.target && e.target.files) {
      const selectedFile = e.target.files[0];
      e.target.value = "";

      if (selectedFile) {
        readFile(selectedFile, "dataURL").then(async (img) => {
          image = new Image();
          image.src = img as string;
          addAnnotation("Stamp", {
            offset: { x: 200, y: 200 },
            height: image.height / 2,
            width: image.width / 2,
            pageNumber: pdfViewer.currentPageNumber,
            author: "img",
            customStamps: [
              {
                customStampName: "Image",
                customStampImageSource: img as string,
              },
            ],
          } as CustomStampSettings);
        });

        dispatch(setStatus(AppStatus.EDITING));
      }
    }
  };

  function template() {
    if (!pdfViewer) return;
    return (
      <div>
        <span
          className="e-pv-total-page-number"
          id="totalPage"
          ref={totalPageRef}
        >
          {pdfViewer === undefined ? " / 0" : " / " + pdfViewer.pageCount}
        </span>
      </div>
    );
  }

  function inputTemplate() {
    return (
      <div>
        <input
          type="text"
          className="e-input-group e-pv-current-page-number"
          id="currentPage"
          ref={currentPageRef}
          onKeyPress={onCurrentPageBoxKeypress}
        />
      </div>
    );
  }

  useEffect(() => {
    setDisplayTool(status !== AppStatus.NO_DOCUMENT);
  }, [status]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <>
        {!displayTool && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              padding: "16px",
            }}
          >
            <DragAndDropFileImport />
          </Box>
        )}
      </>

      <Box
        sx={{
          height: displayTool ? "100%" : "0%",
          width: displayTool ? "100%" : "0%",
        }}
        style={{ visibility: displayTool ? "visible" : "hidden" }}
      >
        <Box id="toolbar_container" className="e-pdf-toolbar">
          <ToolbarComponent
            ref={(scope) => {
              if (scope && scope !== toolbarRef.current) {
                toolbarRef.current = scope;
              }
            }}
            clicked={clickHandler}
          >
            <ItemsDirective>
              <ItemDirective
                prefixIcon="e-pv-first-page-icon"
                id="first_page"
                ref={firstPageRef}
                tooltipText={t("toolbar.firstPage")}
                align="Center"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-previous-page-icon"
                id="previous_page"
                ref={previousPageRef}
                tooltipText={t("toolbar.previousPage")}
                align="Center"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-next-page-icon"
                id="next_page"
                ref={nextPageRef}
                tooltipText={t("toolbar.nextPage")}
                align="Center"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-last-page-icon"
                id="last_page"
                ref={lastPageRef}
                tooltipText={t("toolbar.lastPage")}
                align="Center"
              ></ItemDirective>
              <ItemDirective
                template={inputTemplate}
                tooltipText={t("toolbar.pageNumber")}
                type="Input"
                align="Center"
              ></ItemDirective>
              <ItemDirective
                template={template}
                align="Center"
                tooltipText={t("toolbar.pageNumber")}
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-print-icon"
                tooltipText={t("toolbar.print")}
                id="print"
                align="Right"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-download-icon"
                tooltipText={t("toolbar.download")}
                id="download"
                align="Right"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-annotation-icon"
                tooltipText={t("toolbar.annotation")}
                id="annotation"
                align="Left"
              ></ItemDirective>
              <ItemDirective type="Separator"></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-add-image-icon"
                tooltipText={t("toolbar.addImage")}
                id="image"
                align="Left"
              ></ItemDirective>
              <ItemDirective type="Separator"></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-search-icon"
                tooltipText={t("toolbar.searchKeyword")}
                id="search"
                align="Left"
              ></ItemDirective>
              <ItemDirective type="Separator" align="Left"></ItemDirective>
              <ItemDirective type="Separator" align="Center"></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-fit-icon"
                tooltipText={t("toolbar.fitToPage")}
                id="fit_to_page"
                align="Center"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-zoom-out-icon"
                tooltipText={t("toolbar.zoomOut")}
                id="zoom_out"
                align="Center"
              ></ItemDirective>
              <ItemDirective
                prefixIcon="e-pv-zoom-icon"
                tooltipText={t("toolbar.zoomIn")}
                id="zoom_in"
                align="Center"
              ></ItemDirective>
            </ItemsDirective>
          </ToolbarComponent>
        </Box>
        <RightSheetDialog />
        <Box sx={{ height: "calc(100% - 43px)" }}>
          <PdfViewerComponent
            ref={(scope) => {
              if (scope && scope !== pdfViewer) {
                setPdfViewer(scope);
              }
            }}
            id="container"
            resourceUrl={process.env.REACT_APP_PROD_PDFVIEWER_SERVICE_URL}
            // initialRenderPages={3}
            annotationSettings={{ author: username }}
            stampSettings={{ author: username }}
            documentLoad={onDocumentLoaded}
            documentUnload={onDocumentUnloaded}
            annotationAdd={onAnnotationAdded}
            annotationRemove={onAnnotationRemoved}
            addSignature={signatureAdded}
            pageChange={onPageChange}
            style={{ height: "100%" }}
            isThumbnailViewOpen={true}
            enableCommentPanel={false}
            zoomMode="FitToPage"
            toolbarSettings={{
              showTooltip: true,
              toolbarItems: toolbarItems,
              annotationToolbarItems: annotationToolbarItems,
            }}
            enableToolbar={false}
            enableBookmark={false}
            contextMenuSettings={{
              contextMenuAction: "RightClick",
              contextMenuItems: [ContextMenuItem.Delete],
            }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                ThumbnailView,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
                Print,
              ]}
            />
          </PdfViewerComponent>
        </Box>
        {/* <PdfStatusChip /> */}
        <BottomSheetDialog />

        <input
          hidden
          ref={inputImageRef}
          onChange={handleImage}
          accept="image/jpeg"
          type="file"
        />
      </Box>
    </Box>
  );
};

export default SyncfusionViewer;
