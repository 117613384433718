import React, { FC, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import {
  putDoctype,
  setSelectedDoctypeKey,
  initFields,
} from "features/doctypesSlice";
import api from "services/api";
import DoctypeFormComponent from "components/forms/DoctypeFormComponent/DoctypeFormComponent";
import BarcodeBtn from "../../tools/BarcodeBtn/BarcodeBtn";
import { useFocusContext } from "context/FocusContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Import the icon

const BarcodeTool: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { focusField, focusFocus, registerFocusRef } = useFocusContext();
  const doctypes = useSelector((state: RootState) => state.doctypes.doctypes);
  const selectedDoctypeKey = useSelector(
    (state: RootState) => state.doctypes.selectedDoctypeKey
  );

  const username = useSelector((state: RootState) => state.user.username);
  const filename = useSelector((state: RootState) => state.appState.pdfFileURI);
  const [truncatedFilename, setTruncatedFilename] = useState<string>("");

  useEffect(() => {
    if (filename) {
      const extractedFilename = (filename.split("/").pop() ?? "").replace(
        "pdflock",
        "pdf"
      );
      setTruncatedFilename(extractedFilename);
    }
  }, [filename]);

  useEffect(() => {
    const lastSelectDoctypeKey = localStorage.getItem(`lastSelect_${username}`);
    if (lastSelectDoctypeKey) {
      const { u, code } = JSON.parse(lastSelectDoctypeKey);
      if (u === username && code) {
        dispatch(setSelectedDoctypeKey(code));
      }
    }
  }, [username, dispatch]);

  useEffect(() => {
    const loadFields = async () => {
      const response = await api.get(`/doctypes/${selectedDoctypeKey}`);
      const data = response.data.Data;
      dispatch(putDoctype(data));
      dispatch(initFields());
      focusField(0);
    };

    if (selectedDoctypeKey) {
      loadFields();
    }
  }, [selectedDoctypeKey, dispatch]);

  const handleSelectType = (e: SelectChangeEvent<string>) => {
    const doctypeKey = e.target.value;
    dispatch(setSelectedDoctypeKey(doctypeKey));
    localStorage.setItem(
      `lastSelect_${username}`,
      JSON.stringify({ u: username, code: doctypeKey })
    );
  };

  return (
    <Stack>
      <Tooltip title={truncatedFilename} arrow>
        <Typography
          variant="body2"
          sx={{
            color: "white",
            mb: 1,
            px: 4,
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {truncatedFilename}
        </Typography>
      </Tooltip>

      <div className="bg-white pt-4 rounded-md shadow-lg w-full mb-2 pb-4 px-4">
        <form id="form-data" className="DocumentType">
          <FormControl fullWidth>
            <InputLabel
              id="document-type-select-helper-label"
              sx={{ left: "-14px" }}
            >
              {t("selectType.documentType")}
            </InputLabel>
            <Select
              variant="standard"
              value={selectedDoctypeKey ?? ""}
              onChange={handleSelectType}
              id="document-type-select"
              labelId="document-type-select-helper-label"
              IconComponent={ExpandMoreIcon}
              inputRef={(ref) => registerFocusRef("select", ref)}
            >
              {doctypes
                .slice()
                .sort((a, b) => a.Title.localeCompare(b.Title))
                .map((doctype) => (
                  <MenuItem key={doctype.Key} value={doctype.Key}>
                    {doctype.Title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {selectedDoctypeKey && (
            <>
              <DoctypeFormComponent />
              <BarcodeBtn />
            </>
          )}
        </form>
      </div>
    </Stack>
  );
};

export default BarcodeTool;
