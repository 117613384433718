import React, { forwardRef, useState, useEffect } from "react";
import { TextField, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr-ch";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { checkField, updateField } from "features/doctypesSlice";
import { useDispatch } from "react-redux";

dayjs.extend(customParseFormat);

interface DatePickerFieldProps {
  field: any;
  doctypeKey: string;
  watchField: any;
  inputRef?: React.Ref<HTMLInputElement>;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  field,
  doctypeKey,
  watchField,
  inputRef,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState<Dayjs | null>(null);

  const check = () => {
    dispatch(checkField({ doctypeKey, fieldKey: field.Key }));
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const dateInput = event.target.value;
    const match = dateInput.match(/^(\d{2})\.(\d{2})\.(\d{2})$/);
    if (match) {
      const formattedDate = `${match[1]}.${match[2]}.20${match[3]}`;
      setDateValue(dayjs(formattedDate, "DD.MM.YYYY"));
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "InputValue",
          fieldKey: field.Key,
          value: formattedDate,
        })
      );
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "Value",
          fieldKey: field.Key,
          value: formattedDate,
        })
      );
    }
    check();
  };
  

  const validate = () => {
    check();
  };

  const onChange = (event: any) => {
    var value = event.target.value;
    if (value.length > 10) {
      value = value.slice(0, 10)
    }
    dispatch(
      updateField({
        doctypeKey,
        fieldProperty: "InputValue",
        fieldKey: field.Key,
        value: value,
      })
    );
    dispatch(
      updateField({
        doctypeKey,
        fieldProperty: "Value",
        fieldKey: field.Key,
        value: value,
      })
    );
  };

  const dateHandler = (value: Dayjs | null) => {
    setDateValue(value);
    const formattedDate = value ? value.format("DD.MM.YYYY") : "";
    dispatch(
      updateField({
        doctypeKey,
        fieldProperty: "Value",
        fieldKey: field.Key,
        value: formattedDate,
      })
    );
    dispatch(
      updateField({
        doctypeKey,
        fieldProperty: "InputValue",
        fieldKey: field.Key,
        value: formattedDate,
      })
    );
  };

  useEffect(() => {
    if (field.Value) {
      setDateValue(dayjs(field.Value, "DD.MM.YYYY"));
    } else {
      setDateValue(null);
    }
  }, [field.Value]);

  useEffect(() => {
    if (field.Pos === 2) {
      if (field.Init) {
        if (!field.Default) {
          const today = dayjs();
          setDateValue(today);
          const todayFormatted = today.format("DD.MM.YYYY");
          dispatch(
            updateField({
              doctypeKey,
              fieldProperty: "Value",
              fieldKey: field.Key,
              value: todayFormatted,
            })
          );
          dispatch(
            updateField({
              doctypeKey,
              fieldProperty: "InputValue",
              fieldKey: field.Key,
              value: todayFormatted,
            })
          );
        }
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "Init",
            fieldKey: field.Key,
            value: false,
          })
        );
      }
    }
    
  }, [field.Init]);

  useEffect(() => {
    if (watchField && watchField.Value) {
      const baseDate = dayjs(watchField.Value, "DD.MM.YYYY");
      if (baseDate.isValid()) {
        const delta = field.DeltaDateInDays || 0;
        const newDate = baseDate.add(delta, "day");
        setDateValue(newDate);
        const dateFormatted = newDate.format("DD.MM.YYYY");
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "Value",
            fieldKey: field.Key,
            value: dateFormatted,
          })
        );
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "InputValue",
            fieldKey: field.Key,
            value: dateFormatted,
          })
        );
      }
    }
  }, [watchField?.Value]);

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ch">
        <DatePicker
          value={dateValue}
          onChange={dateHandler}
          onAccept={validate}
          onError={validate}
          inputFormat="DD.MM.YYYY"
          inputRef={inputRef}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field.Label}
              required={field.Required}
              error={field.Error !== undefined && field.Error !== null}
              helperText={t(field.Error) || ""}
              value={field.InputValue || ""}
              onChange={onChange}
              onBlur={onBlur}
              variant="standard"
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
export default DatePickerField;
