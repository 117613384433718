import { ChangeEvent, useEffect, useState, FormEvent, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import api from "services/api";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../context/SnackbarContext";
import { useDialog } from "../../context/DialogContext";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";

type LoginResponse = {
  Data: string;
  Success: boolean;
  Message: string;
};

interface RouteParams {
  ged: string;
}

const Login = () => {
  const { t } = useTranslation();
  const { ged } = useParams<RouteParams>();
  const { showSnackbar } = useSnackbar();
  const { forceCloseDialog } = useDialog();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ username: boolean; password: boolean }>({
    username: false,
    password: false,
  });

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Ref for the username input
  const usernameRef = useRef<HTMLInputElement>(null);

    // Check if GED exists on page load
    useEffect(() => {
      const checkGedExists = async () => {
        try {
          const response = await api.get(`/auth/ged?ged=${ged}`);
          if (response.data.Data === false) {
            history.push(`/notfound`);
          }
        } catch (error) {
          console.error("Failed to check GED existence:", error);
          history.push(`/notfound`);
        }
      };
      forceCloseDialog();
      checkGedExists();
      if (usernameRef.current) {
        usernameRef.current.focus();
      }
    }, [forceCloseDialog, ged, history]);

  useEffect(() => {
    forceCloseDialog();
    // Focus the username input on page load
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, [forceCloseDialog]);

  const onBlur = (field: string) => {
    if (field === "username") {
      setErrors((prev) => ({ ...prev, username: !username }));
    } else if (field === "password") {
      setErrors((prev) => ({ ...prev, password: !password }));
    }
  };

  const update = (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (field === "username") {
      setUsername(value);
      setErrors((prev) => ({ ...prev, username: !value }));
    } else if (field === "password") {
      setPassword(value);
      setErrors((prev) => ({ ...prev, password: !value }));
    }
  };

  const validateForm = () => {
    let valid = true;
    if (!username) {
      setErrors((prev) => ({ ...prev, username: true }));
      valid = false;
    }
    if (!password) {
      setErrors((prev) => ({ ...prev, password: true }));
      valid = false;
    }
    return valid;
  };

  const submitLogin = async (event: FormEvent) => {
    event.preventDefault();
    setLoginInProgress(true);

    if (validateForm()) {
      try {
        const response = await api.post<LoginResponse>("/auth/login", {
          username,
          password,
        });
        localStorage.setItem("token", response.data.Data);
        const decodedToken: any = jwtDecode(response.data.Data);
        dispatch(
          login({
            isLogged: true,
            username: decodedToken.username,
            token: response.data.Data,
          })
        );
        showSnackbar({
          message: t("loginSuccess"),
          severity: "success",
          duration: 3000,
        });
        history.push(`/${ged}/edocindexweb`);
      } catch (error: any) {
        console.error(error);
        let errorMessage = t("internalError");

        if (error.response) {
          errorMessage = error.response.data?.Message || errorMessage;
        }

        showSnackbar({
          message: errorMessage,
          severity: "error",
        });

        setUsername("");
        setPassword("");
      } finally {
        setLoginInProgress(false);
      }
    } else {
      setLoginInProgress(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Paper elevation={3} sx={{ p: 5, textAlign: "center", width: "400px" }}>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Connexion
        </Typography>
        <form id="form-login" method="post" onSubmit={submitLogin}>
          <TextField
            sx={{ mb: 4 }}
            label={t("username")}
            fullWidth
            type="text"
            autoComplete="username"
            required
            name="username"
            value={username}
            onChange={update("username")}
            onBlur={() => onBlur("username")}
            error={errors.username}
            helperText={errors.username ? t("validation.required") : ""}
            inputRef={usernameRef}
          />
          <TextField
            sx={{ mb: 4 }}
            label={t("password")}
            fullWidth
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            required
            name="password"
            value={password}
            onChange={update("password")}
            onBlur={() => onBlur("password")}
            error={errors.password}
            helperText={errors.password ? t("validation.required") : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            disabled={loginInProgress}
            variant="contained"
            type="submit"
          >
            {loginInProgress ? <CircularProgress size={24} /> : <> {t("login")}</>}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
