// features/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CresusOption {
  Value: string;
  Label: string;
}

interface UserState {
  isLogged: boolean;
  token: string;
  username: string;
  clientName: string;
  code: string;
  hasCresus: boolean;
  cresusOptions: CresusOption[];
  displayBrWithClient: boolean;
  gedLink: string;
}

const initialState: UserState = {
  isLogged: false,
  token: '',
  username: '',
  clientName: '',
  code: '',
  hasCresus: false,
  cresusOptions: [],
  displayBrWithClient: false,
  gedLink: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action: PayloadAction<Partial<UserState>>) {
      state.isLogged = true;
      state.token = action.payload.token || state.token || '';
      state.username = action.payload.username || state.username || '';
      state.clientName = action.payload.clientName || state.clientName || '';
      state.code = action.payload.code || state.code || '';
      state.hasCresus = action.payload.hasCresus || state.hasCresus || false;
      state.cresusOptions = action.payload.cresusOptions || [];
      state.displayBrWithClient = action.payload.displayBrWithClient || state.displayBrWithClient || false;
      state.gedLink = action.payload.gedLink || state.gedLink || '';
    },
    logout(state) {
      state.isLogged = false;
      state.token = '';
      state.username = '';
      state.clientName = '';
      state.code = '';
      state.hasCresus = false;
      state.cresusOptions = [];
      state.displayBrWithClient = false;
      state.gedLink = '';
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
