import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "context/SnackbarContext";
import {
  Button,
  Tooltip,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { RootState } from "services/store";
import { useDialog } from "context/DialogContext";
import imageAccounting from "assets/images/ACCOUNTING.png";
import Image from "components/core/Image/Image";
import styled from "@emotion/styled";

const SpinnerWrapper = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: 5,
});

const CresusAction: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { showDialog, forceCloseDialog } = useDialog();
  const hasCresus = useSelector((state: RootState) => state.user.hasCresus);
  const cresusOptions = useSelector((state: RootState) => state.user.cresusOptions); // Get Cresus options from store
  const [enableCresusButton, setEnableCresusButton] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [selectedService, setSelectedService] = useState<string>(""); // State for selected service
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const checkInterval = useRef<NodeJS.Timeout | null>(null);

  // Automatically select the only option if there's just one
  useEffect(() => {
    if (cresusOptions.length >= 1) {
      setSelectedService(cresusOptions[0].Value);
    }
  }, [cresusOptions]);

  const checkCresusAvailability = async () => {
    if (selectedService === "") {
      setEnableCresusButton(false);
      return;
    }
    setIsLoadingCheck(true);
    try {
      const responses = await Promise.all([
        api.get(`/cresus/download?action=check&service=${selectedService}`),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ]);
      const response = responses[0];
      if (response.data) {
        setEnableCresusButton(true);
        if (checkInterval.current) {
          clearInterval(checkInterval.current);
          checkInterval.current = null;
        }
      } else {
        if (!checkInterval.current) {
          checkInterval.current = setInterval(() => {
            checkCresusAvailability();
          }, 5 * 60 * 1000); // Retry every 5 minutes if not available
        }
        setEnableCresusButton(false);
      }
    } catch (error) {
      console.error("Error checking Cresus availability:", error);
    } finally {
      setIsLoadingCheck(false);
    }
  };

  useEffect(() => {
    checkCresusAvailability();

    return () => {
      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
    };
  }, [selectedService]);

  const handleMouseOver = () => {
    if (!enableCresusButton && !hoverTimeout.current) {
      checkCresusAvailability();
      hoverTimeout.current = setTimeout(() => {
        hoverTimeout.current = null;
      }, 500);
    }
  };

  const extractFilename = (contentDisposition: string) => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }

    return "downloaded-cresus.txt";
  };

  const handleCresus = async () => {
    const confirm = await showDialog({
      type: "confirmation",
      title: "Confirmation",
      message: t("promptCresus"),
      severity: "info",
    });

    if (!confirm) return;

    try {
      showDialog({
        type: "lock",
        title: t("headerMsg.loading"),
        message: t("headerMsg.loading"),
        severity: "info",
        progress: true,
      });

      const response = await api.get(
        `/cresus/download?action=download&service=${selectedService}`,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const contentDisposition = response.headers["content-disposition"];
        let filename = extractFilename(contentDisposition);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        showSnackbar({
          message: t("fileCresusDownloadedSuccessfully"),
          severity: "success",
        });

        checkCresusAvailability();
      } else {
        showSnackbar({
          message: t("error.cannotDownloadCresusFile"),
          severity: "error",
        });
      }
    } catch (error: any) {
      console.error("Error downloading Cresus file:", error);
      if (error?.response?.status === 403) {
        showSnackbar({
          message: t("error.cannotDownloadCresusFileNoPermissions"),
          severity: "error",
        });
      } else if (error?.response?.status === 425) {
        showSnackbar({
          message: t("error.cannotDownloadCresusFileNoInputFiles"),
          severity: "error",
        });
      } else {
        showSnackbar({
          message: t("error.cannotDownloadCresusFile"),
          severity: "error",
        });
      }
    } finally {
      forceCloseDialog();
      checkCresusAvailability();
    }
  };

  const handleServiceChange = (e: SelectChangeEvent<string>) => {
    setSelectedService(e.target.value as string);
  };

  return (
    <>
      {hasCresus && (
        <div
          
          style={{ display: "flex", alignItems: "center" }}
        >
          {cresusOptions.length > 1 && ( // Show select only if more than one option
            <FormControl sx={{ ml: 2 }}>
              <Select
                size="small"
                value={selectedService}
                onChange={handleServiceChange}
                sx={{ width: 196 }}
              >
                {cresusOptions.map((option) => (
                  <MenuItem key={option.Value} value={option.Value}>
                    {option.Label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

<div
onMouseOver={handleMouseOver}
>
          <Tooltip title={t("tooltip.exportCresus")} arrow>
            <Button
              
              disabled={!enableCresusButton || !selectedService} // Disable button if no service is selected
              onClick={handleCresus}
              variant="contained"
              component="label"
              startIcon={
                isLoadingCheck && !enableCresusButton ? (
                  <SpinnerWrapper>
                    <CircularProgress size={24} sx={{ color: "inherit" }} />
                  </SpinnerWrapper>
                ) : (
                  <Image
                    style={{ backgroundImage: `url(${imageAccounting})` }}
                  />
                )
              }
              size="large"
              sx={{ ml: 1.0, height: 40, width: 30 }}
            />
          </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

export default CresusAction;
