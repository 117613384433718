import "./MainAction.css";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Divider,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import { PDFParsingError, PDFDocument } from "pdf-lib";
import { readFile } from "../../../utils/ReadFile";
import styled from "@emotion/styled";
import {
  FileManagerComponent,
  Inject,
  DetailsView,
  NavigationPane,
  Toolbar,
  FileSelectEventArgs,
  FileOpenEventArgs,
} from "@syncfusion/ej2-react-filemanager";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { AppStatus, setPdfFileURI, setStatus } from "features/appStateSlice";
import { L10n } from "@syncfusion/ej2-base";
import api from "services/api";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "context/SnackbarContext";
import { useDialog } from "context/DialogContext";
import syncfusionTranslationEN from "services/i18n/locales/en/syncfusion.json";
import syncfusionTranslationFR from "services/i18n/locales/fr/syncfusion.json";
import syncfusionTranslationDE from "services/i18n/locales/de/syncfusion.json";
import syncfusionTranslationIT from "services/i18n/locales/it/syncfusion.json";
import {
  ErrorImportPDF,
  ErrorPasswordProtection,
  usePdfViewer,
} from "context/PdfViewerContext";
import imageAddpdf from "assets/images/ADDPDF.png";
import imageSend from "assets/images/SEND.png";
import imageSave from "assets/images/SAVE.png";
import imageAccounting from "assets/images/ACCOUNTING.png";
import imageDossier from "assets/images/DOSSIER.png";
import imageAvatar from "assets/images/AVATAR.png";
import imageCloseDocument from "assets/images/CLOSE_DOCUMENT.png";
import CloseIcon from "@mui/icons-material/Close";
import ClosePDFAction from "components/actions/ClosePDFAction/ClosePDFAction";
import Image from "components/core/Image/Image";
import { useParams } from "react-router-dom";
import {
  clearAnnotationsFromDB,
  clearPDFInDB,
  getPDFFromDB,
  savePDFDebounced,
} from "services/indexeddb";
import { PdfViewerComponent } from "@syncfusion/ej2-react-pdfviewer";
import { setSelectedDoctypeKey } from "features/doctypesSlice";

L10n.load({
  en: syncfusionTranslationEN,
  fr: syncfusionTranslationFR,
  de: syncfusionTranslationDE,
  it: syncfusionTranslationIT,
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  borderRadius: "6px",
  maxWidth: "1024px",
  pt: "48px",
  pb: "72px",
  bgcolor: "background.paper",
  boxShadow: 24,
};

interface RouteParams {
  ged: string;
}

const MainAction: FC = () => {
  const { t, i18n } = useTranslation();
  const { ged } = useParams<RouteParams>();
  const dispatch = useDispatch();

  const status = useSelector((state: RootState) => state.appState.status);
  const pdfFileURI = useSelector(
    (state: RootState) => state.appState.pdfFileURI
  );
  const currentPage = useSelector(
    (state: RootState) => state.appState.currentPage
  );
  const clientName = useSelector((state: RootState) => state.user.clientName);
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const username = useSelector((state: RootState) => state.user.username);
  const code = useSelector((state: RootState) => state.user.code);
  const hasCresus = useSelector((state: RootState) => state.user.hasCresus);

  const [displaySaveButton, setDisplaySaveButton] = useState(false);
  const [displayCresusButton, setDisplayCresusButton] = useState(true);
  const [displaySendButton, setDisplaySendButton] = useState(false);
  const [displayCloseButton, setDisplayCloseButton] = useState(false);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [enableDeleteButton, setEnableDeleteButton] = useState(false);
  const [enableOpenButton, setEnableOpenButton] = useState(false);
  const [enableUnlockButton, setEnableUnlockButton] = useState(false);

  const { showSnackbar } = useSnackbar();
  const { showDialog, forceCloseDialog } = useDialog();
  const {
    pdfViewer,
    loadPdfByBlob,
    loadPdfByBase64,
    loadFromIndexedDb,
    closePdf,
    goToPage,
  } = usePdfViewer();

  const [isModalSyncfusionExplorerOpened, setIsModalSyncfusionExplorerOpened] =
    useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const fileManagerRef = useRef<FileManagerComponent | null>(null);
  const navigationPaneRef = useRef<HTMLDivElement | null>(null);
  const splitBarRef = useRef<HTMLDivElement | null>(null);
  const breadCrumbBarRef = useRef<HTMLDivElement | null>(null);

  const beforeSendHandler = (args: any) => {
    const token = localStorage.getItem("token");
    args.ajaxSettings.beforeSend = (args: any) => {
      args.httpRequest.setRequestHeader("Authorization", `Bearer ${token}`);
      args.httpRequest.setRequestHeader("Selected-GED", ged);
    };
  };

  const updateNaming = () => {
    const treeSpan = document.querySelector(
      "#file_tree > ul > li > div.e-text-content.e-icon-wrapper > span"
    );
    if (treeSpan && treeSpan.textContent) {
      treeSpan.textContent = clientName;
    }
    const breadcrumbSpan = document.querySelector(
      "#file_breadcrumbbar > ul > li > span"
    );
    if (breadcrumbSpan && breadcrumbSpan.textContent) {
      breadcrumbSpan.textContent = clientName;
    }
    const searchBar = document.querySelector(
      "#file_search"
    ) as HTMLInputElement;
    if (searchBar) {
      searchBar.placeholder = t("search");
    }

    const breadcrumbSpanListElement = document.querySelector(
      "#file_breadcrumbbar > ul > li:nth-child(1) > a"
    );
    if (breadcrumbSpanListElement && breadcrumbSpanListElement.textContent) {
      breadcrumbSpanListElement.textContent = clientName;
    }
  };

  const createObservers = () => {
    const observerNavPaneTarget = navigationPaneRef.current;
    if (observerNavPaneTarget) {
      const resizeObserver = new ResizeObserver(() => {
        updateNaming();
      });
      resizeObserver.observe(observerNavPaneTarget);
    }

    const observerSplitBarTarget = splitBarRef.current;
    if (observerSplitBarTarget) {
      const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation: any) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class"
          ) {
            const barIsActive =
              mutation.target.classList.contains("e-split-bar-active");
            if (breadCrumbBarRef.current) {
              if (barIsActive) {
                // Add the class to hide breadcrumb bar
                breadCrumbBarRef.current.classList.add("hide-breadcrumbbar");
              } else {
                breadCrumbBarRef.current.classList.remove("hide-breadcrumbbar");
                updateNaming();
              }
            }
          }
        });
      });
      mutationObserver.observe(observerSplitBarTarget, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEV_MODE === "true") {
      console.log("-------------------------------");
      console.log("Status", status);
      console.log("PdfFileURI", pdfFileURI);
      console.log("Current Page", currentPage);
      console.log("-------------------------------");
    }
  }, [status, pdfFileURI, currentPage]);

  const preparePdf = async (viewer: PdfViewerComponent, finalize: boolean) => {
    const blob = await viewer.saveAsBlob();
    const buffer = await blob.arrayBuffer();
    const pdf = await PDFDocument.load(buffer);

    pdf.setAuthor(process.env.REACT_APP_AUTHOR ?? "eDocIndexWeb");
    pdf.setProducer(process.env.REACT_APP_PRODUCER ?? "2m Interactive Sàrl");
    pdf.setCreator(process.env.REACT_APP_CREATOR ?? "eDocIndexWeb");
    pdf.setModificationDate(new Date());

    const newPdf = await pdf.save();
    const newBlob = new Blob([newPdf], { type: "application/pdf" });
    return new File([newBlob], pdfFileURI);
  };

  const handleFileImport = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!pdfViewer) return;

    const selectedFile = event.target.files?.[0];
    event.target.value = "";

    if (!selectedFile || !selectedFile.name.toLowerCase().endsWith(".pdf")) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("modal.notPdfFile"),
        severity: "error",
      });
      return;
    }

    if (status === AppStatus.EDITING) {
      const confirm = await showDialog({
        type: "confirmation",
        title: "Confirmation",
        message: t("promptMessage"),
        severity: "warning",
      });
      if (!confirm) return;
    }

    try {
      showDialog({
        type: "lock",
        title: t("headerMsg.loading"),
        message: t("headerMsg.importing"),
        severity: "info",
        progress: true,
      });

      dispatch(setStatus(AppStatus.LOADING));

      if (pdfFileURI) {
        await closePdf(pdfFileURI);
      }

      // Attempt to load the selected PDF by Blob
      await loadPdfByBlob(selectedFile, selectedFile.name);
    } catch (error: any) {
      if (error instanceof ErrorPasswordProtection) {
        // Handle password-protected PDF error
        showDialog({
          type: "base",
          title: t("modal.errorOccurred"),
          message: t("modal.errorPasswordProtection"),
          severity: "error",
        });
      } else if (error instanceof ErrorImportPDF) {
        // Handle password-protected PDF error
        showDialog({
          type: "base",
          title: t("modal.errorOccurred"),
          message: t("modal.errorImportPDF"),
          severity: "error",
        });
      } else {
        showDialog({
          type: "base",
          title: t("modal.errorOccurred"),
          message: t("error.trySendingAgain"),
          severity: "error",
        });
      }
    }
  };

  const handleFileOpen = async (args: any) => {
    if (!pdfViewer) return;

    const openedFileName = args.fileDetails.name;
    const filePath =
      args.fileDetails.filterPath.replace(/\\/g, "/") + openedFileName;
    const fileType = args.fileDetails.type;

    if (fileType !== ".pdf" && fileType !== ".pdflock") {
      return;
    }

    if (status === AppStatus.EDITING) {
      const confirm = await showDialog({
        type: "confirmation",
        title: "Confirmation",
        message: t("promptMessage"),
        severity: "warning",
      });
      if (!confirm) return;
    }

    if (fileType === ".pdflock") {
      handleUnlockAction(args);
      return;
    }

    if (fileType === ".pdf") {
      try {
        showDialog({
          type: "lock",
          title: t("headerMsg.loading"),
          message: t("headerMsg.importing"),
          severity: "info",
          progress: true,
        });

        setIsModalSyncfusionExplorerOpened(false);

        dispatch(setStatus(AppStatus.LOADING));

        const formData = new FormData();
        formData.append("Path", filePath);

        const res = await api.post("/file-manager/document", formData);
        if (pdfFileURI) {
          await closePdf(pdfFileURI);
        }
        await loadPdfByBase64(res.data.Data.FileBase64, res.data.Data.FileName);
      } catch (error: any) {
        if (error instanceof ErrorPasswordProtection) {
          // Handle password-protected PDF error
          showDialog({
            type: "base",
            title: t("modal.errorOccurred"),
            message: t("modal.errorPasswordProtection"),
            severity: "error",
          });
        } else if (error instanceof ErrorImportPDF) {
          // Handle password-protected PDF error
          showDialog({
            type: "base",
            title: t("modal.errorOccurred"),
            message: t("modal.errorImportPDF"),
            severity: "error",
          });
        } else {
          showDialog({
            type: "base",
            title: t("modal.errorOccurred"),
            message: t("error.trySendingAgain"),
            severity: "error",
          });
        }
      }
    }
  };

  const hasPdfFirstPageBr = () => {
    if (!pdfViewer) return false;
    const annotation = pdfViewer.annotationCollection.find(
      (annotation) =>
        annotation.author.startsWith(process.env.REACT_APP_AUTHOR) &&
        annotation.pageNumber === 0
    );
    return annotation !== undefined;
  };

  const sendPdf = async () => {
    if (!pdfViewer) {
      return;
    }
    if (!pdfFileURI) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("internalError"),
        severity: "error",
      });
      return;
    }
    if (status === AppStatus.NO_DOCUMENT || !pdfFileURI) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("error.noFileLoaded"),
        severity: "error",
      });
      return;
    }

    if (!hasPdfFirstPageBr()) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("error.barcodeNotPresent"),
        severity: "error",
      });
      goToPage(1);
      return;
    }

    showDialog({
      type: "lock",
      title: t("headerMsg.loading"),
      message: t("headerMsg.sending"),
      severity: "info",
      progress: true,
    });

    try {
      const newFile = await preparePdf(pdfViewer, true);

      const formData = new FormData();
      formData.append("FormFile", newFile);
      formData.append("FileName", pdfFileURI);

      const res = await api.post("/pdffile", formData);
      showSnackbar({ message: res.data.Message, severity: "success" });
      if (pdfFileURI) {
        closePdf(pdfFileURI);
      }
      forceCloseDialog();
    } catch (error: any) {
      console.error(error);
      forceCloseDialog();
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: error.response?.data?.Message || t("internalError"),
        severity: "error",
      });
    }
  };

  function containsUsername(path: string, username: string): boolean {
    const regex = new RegExp(`\/[^\/]*\/[^\/]*\/${username}\/`);
    return regex.test(path);
  }

  const handleFileSelect = () => {
    if (fileManagerRef.current) {
      fileManagerRef.current.clearSelection();
    }
    if (fileManagerRef.current) {
      const selectedItems = fileManagerRef.current.getSelectedFiles();
      setSelectedFilesCount(selectedItems.length);

      // Check for the types of selected files
      const pdfFiles: any = selectedItems.filter(
        (item: any) => item.type === ".pdf"
      );
      const pdflockFiles = selectedItems.filter(
        (item: any) => item.type === ".pdflock"
      );
      const directories: any = selectedItems.filter(
        (item: any) => !item.isFile
      );

      if (pdfFiles.length > 0) {
        setEnableDeleteButton(
          containsUsername(pdfFiles[0].filterPath, username)
        );
      } else {
        setEnableDeleteButton(false);
      }

      // setEnableOpenButton(pdfFiles.length > 0 || directories.length > 0);
      setEnableOpenButton(pdfFiles.length > 0);
      setEnableUnlockButton(pdflockFiles.length > 0);
    }
  };

  const handleUnlockFileOption = () => {
    if (fileManagerRef.current) {
      const selectedItems = fileManagerRef.current.getSelectedFiles();
      handleUnlockAction({
        fileDetails: selectedItems[0],
      });
    }
  };

  const handleOpenFileOption = () => {
    if (fileManagerRef.current) {
      const selectedItems = fileManagerRef.current.getSelectedFiles();
      handleFileOpen({
        cancel: false,
        module: "DetailsView",
        name: "fileOpen",
        fileDetails: selectedItems[0],
      });
      setIsModalSyncfusionExplorerOpened(false);
    }
  };

  const handleDeleteAction = async () => {
    const confirm = await showDialog({
      type: "confirmation",
      title: "Confirmation",
      message: t("confirm.deleteDocument"),
      severity: "error",
    });
    if (!confirm) return;

    if (!fileManagerRef.current) return;
    showDialog({
      type: "lock",
      title: t("headerMsg.loading"),
      message: t("headerMsg.loading"),
      severity: "info",
      progress: true,
    });

    const selectedItems = fileManagerRef.current.getSelectedFiles();
    const pdfFiles = selectedItems.filter((item: any) => item.type === ".pdf");

    if (pdfFiles.length === 0) {
      forceCloseDialog();
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("error.noFileSelected"),
        severity: "error",
      });
      return;
    }

    const pdfFile: any = pdfFiles[0];
    const relativeFilename = `${pdfFile.filterPath}${pdfFile.name}`.replace(
      /^\/+/,
      ""
    );

    const formData = new FormData();
    formData.append("FileName", relativeFilename);

    try {
      const responses = await Promise.all([
        api.delete("/pdffile/delete", { data: formData }),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]);
      const response = responses[0];

      if (response.status === 200) {
        showSnackbar({ message: response.data.Message, severity: "success" });
      }
    } catch (error: any) {
      console.error(error);
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: error.response?.data?.Message || t("internalError"),
        severity: "error",
      });
    } finally {
      forceCloseDialog();
      fileManagerRef.current.clearSelection();
      fileManagerRef.current.refreshFiles();
    }
  };

  const handleUnlockAction = async (args: any) => {
    if (!fileManagerRef.current) return;
    showDialog({
      type: "lock",
      title: t("headerMsg.loading"),
      message: t("headerMsg.loading"),
      severity: "info",
      progress: true,
    });

    const pdflockFile: any = args.fileDetails;

    if (!pdflockFile) return;

    const relativeFilename =
      `${pdflockFile.filterPath}${pdflockFile.name}`.replace(/^\/+/, "");

    if (relativeFilename === pdfFileURI) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("error.theFileCannotBeUnlockedBecauseItsOpenInYourBrowser"),
        severity: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("FileName", relativeFilename);

    try {
      const responses = await Promise.all([
        api.post("/pdffile/unlock", formData),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]);
      const response = responses[0];

      if (response.status === 200) {
        showSnackbar({ message: response.data.Message, severity: "success" });
      }
    } catch (error: any) {
      console.error(error);
      showSnackbar({
        message: error.response?.data?.Message || t("internalError"),
        severity: "error",
      });
    } finally {
      forceCloseDialog();
    }

    fileManagerRef.current.clearSelection();
    fileManagerRef.current.refreshFiles();
  };

  const handleSave = async () => {
    if (!pdfViewer || !pdfFileURI) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("error.noFileLoaded"),
        severity: "error",
      });
      return;
    }

    showDialog({
      type: "lock",
      title: t("headerMsg.loading"),
      message: t("headerMsg.saving"),
      severity: "info",
      progress: true,
    });

    setTimeout(async () => {
      try {
        const newFile = await preparePdf(pdfViewer, false);

        const formData = new FormData();
        formData.append("FormFile", newFile);
        formData.append("FileName", pdfFileURI);

        const res = await api.post("/pdffile/work", formData);
        showSnackbar({ message: res.data.Message, severity: "success" });

        dispatch(setPdfFileURI(res.data.Data));
        dispatch(setStatus(AppStatus.LOADED));
        await clearPDFInDB();
        await clearAnnotationsFromDB();
        forceCloseDialog();
      } catch (error: any) {
        console.error(error);
        forceCloseDialog();
        showDialog({
          type: "base",
          title: t("modal.errorOccurred"),
          message: error.response?.data?.Message || t("internalError"),
          severity: "error",
        });
      }
    }, 500);
  };

  const handleFinaliseFile = () => {
    if (!pdfFileURI) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("error.noFileLoaded"),
        severity: "error",
      });
      return;
    }
    showDialog({
      type: "confirmation",
      title: t("dialog.confirmSendTitle"),
      message: t("dialog.confirmSend"),
      severity: "info",
      onConfirm: sendPdf,
    });
  };

  const handleCloseDocument = async () => {
    if (!pdfViewer) return;

    if (status === AppStatus.EDITING) {
      const confirm = await showDialog({
        type: "confirmation",
        title: "Confirmation",
        message: t("promptMessage"),
        severity: "warning",
      });
      if (!confirm) return;
    }
    if (pdfFileURI) {
      closePdf(pdfFileURI);
    }
    showSnackbar({
      message: t("documentClosed"),
      severity: "info",
    });
  };

  useEffect(() => {
    if (!pdfViewer) return;
    return;
    const checkForExistingPDF = async () => {
      const existingPDF = await getPDFFromDB();
      if (existingPDF) {
        const confirmReload = await showDialog({
          type: "confirmation",
          title: t("dialog.confirmReloadTitle"),
          message: t("dialog.confirmReloadMessage"),
          severity: "info",
        });

        if (confirmReload) {
          dispatch(setStatus(AppStatus.LOADING));
          await loadFromIndexedDb();
          // await loadPdfByBlob(existingPDF, "AutoSaved.pdf");
        } else {
          await clearPDFInDB();
          await clearAnnotationsFromDB();
        }
      }
    };

    checkForExistingPDF();
  }, [pdfViewer]);

  useEffect(() => {
    setDisplaySaveButton(
      status === AppStatus.LOADED || status === AppStatus.EDITING
    );
  }, [status]);

  useEffect(() => {
    setDisplaySendButton(
      status === AppStatus.LOADED || status === AppStatus.EDITING
    );
  }, [status]);

  useEffect(() => {
    setDisplayCloseButton(
      status === AppStatus.LOADED || status === AppStatus.EDITING
    );
  }, [status]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "white",
        pt: 8,
        height: "100%",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
      >
        <Tooltip title={t("tooltip.import")} arrow placement="right">
          <Button
            variant="contained"
            component="label"
            startIcon={
              <Image style={{ backgroundImage: `url(${imageAddpdf})` }} />
            }
            size="large"
            sx={{ my: 0.5, height: 40, width: 30 }}
          >
            <input
              ref={inputFileRef}
              hidden
              onChange={handleFileImport}
              accept="application/pdf"
              type="file"
            />
          </Button>
        </Tooltip>
        <Tooltip title={t("tooltip.openSavedDocument")} arrow placement="right">
          <Button
            onClick={() => {
              setIsModalSyncfusionExplorerOpened(true);
            }}
            variant="contained"
            component="label"
            startIcon={
              <Image style={{ backgroundImage: `url(${imageDossier})` }} />
            }
            size="large"
            sx={{ my: 0.5, height: 40, width: 30 }}
          />
        </Tooltip>
        <Modal
          open={isModalSyncfusionExplorerOpened}
          onClose={() => setIsModalSyncfusionExplorerOpened(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, position: "relative" }}>
            <FileManagerComponent
              created={() => {
                const navPane = document.querySelector("#file_navigation");
                if (navPane) {
                  navigationPaneRef.current = navPane as HTMLDivElement;
                }
                const splitBar = document.querySelector(
                  "#file_layout div.e-split-bar.e-split-bar-horizontal"
                );
                if (splitBar) {
                  splitBarRef.current = splitBar as HTMLDivElement;
                }

                const breadCrumbBar = document.querySelector(
                  "#file_breadcrumbbar"
                );
                if (breadCrumbBar) {
                  breadCrumbBarRef.current = breadCrumbBar as HTMLDivElement;
                }
                createObservers();
              }}
              ref={fileManagerRef}
              locale={i18n.language}
              fileOpen={handleFileOpen}
              fileLoad={updateNaming}
              success={updateNaming}
              style={{ backgroundColor: "white", height: "calc(100% - 50px)" }} // Adjust height for custom toolbar
              id="file"
              ajaxSettings={{
                url: `${process.env.REACT_APP_API_URL}/file-manager/operation`,
              }}
              beforeSend={beforeSendHandler}
              contextMenuSettings={{
                layout: ["Refresh"],
                visible: false,
              }}
              toolbarSettings={{
                items: ["Open", "Delete"],
              }}
              view="Details"
              allowDragAndDrop={false}
              allowMultiSelection={false}
              fileSelect={handleFileSelect}
              type="PDF"
            >
              <Inject services={[DetailsView, NavigationPane]} />
            </FileManagerComponent>
            <Box
              sx={{
                position: "absolute",
                top: 4,
                width: "100%",
                px: "4px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flex: 1, flexGrow: 1 }}></Box>
              <Box sx={{ flex: 1, flexGrow: 1, textAlign: "right" }}>
                <IconButton
                  aria-label="close"
                  onClick={() => setIsModalSyncfusionExplorerOpened(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 16,
                width: "100%",
                px: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flex: 1, flexGrow: 1 }}>
                {(enableDeleteButton || false) && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteAction}
                    disabled={!enableDeleteButton}
                  >
                    {t("delete")}
                  </Button>
                )}
              </Box>
              <Box sx={{ flex: 1, flexGrow: 1, textAlign: "right" }}>
                {(enableUnlockButton || false) && (
                  <Button
                    sx={{ ml: 1 }}
                    variant="contained"
                    color="warning"
                    onClick={() => handleUnlockFileOption()}
                    disabled={!enableUnlockButton}
                  >
                    {t("unlock")}
                  </Button>
                )}

                {(enableOpenButton || true) && (
                  <Button
                    sx={{ ml: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenFileOption()}
                    disabled={!enableOpenButton}
                  >
                    {t("open")}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>

        <Divider sx={{ my: 2, width: "50%" }} />

        <Tooltip title={t("tooltip.save")} arrow placement="right">
          <Button
            disabled={!displaySaveButton}
            onClick={handleSave}
            variant="contained"
            component="label"
            startIcon={
              <Image style={{ backgroundImage: `url(${imageSave})` }} />
            }
            size="large"
            sx={{ my: 0.5, height: 40, width: 30 }}
          />
        </Tooltip>
        <Tooltip title={t("tooltip.finalize")} arrow placement="right">
          <Button
            disabled={!displaySendButton}
            variant="contained"
            onClick={handleFinaliseFile}
            component="label"
            startIcon={
              <Image style={{ backgroundImage: `url(${imageSend})` }} />
            }
            size="large"
            sx={{ my: 0.5, height: 40, width: 30 }}
          />
        </Tooltip>
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
        sx={{ mb: 2 }}
      >
        <Tooltip
          title={t("tooltip.closeCurrentDocument")}
          arrow
          placement="right"
        >
          <Button
            disabled={!displayCloseButton}
            variant="contained"
            component="label"
            startIcon={
              <Image
                style={{ backgroundImage: `url(${imageCloseDocument})` }}
              />
            }
            size="large"
            sx={{ my: 0.5, height: 40, width: 30 }}
            onClick={handleCloseDocument}
          />
        </Tooltip>
        {/* <Divider sx={{ my: 2, width: "50%" }} />
        {isLogged && (
          <>
            <img
              src={imageAvatar}
              className="w-4 inline-block mx-auto"
              alt="avatar"
            />
            <Typography
              variant="caption"
              title={username}
              sx={{ mt: 0.5, fontWeight: "bold" }}
            >
              {username &&
                (username.length > 12
                  ? `${username.slice(0, 8)}...`
                  : username)}
            </Typography>
          </>
        )} */}
      </Stack>
    </Box>
  );
};

export default MainAction;
