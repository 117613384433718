import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Guid } from "guid-typescript";
import {
  AnnotationType,
  PdfViewerComponent,
} from "@syncfusion/ej2-react-pdfviewer";
import { AppStatus, setStatus, setPdfFileURI } from "features/appStateSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  savePDFToDB,
  saveAnnotationToDB,
  removeAnnotationFromDB,
  getPDFFromDB,
} from "services/indexeddb";
import { IBarcodeListData } from "models/IBarcodeListData";
import { PDFDocument } from "pdf-lib";
import { RootState } from "services/store";
import api from "services/api";
import { setSelectedDoctypeKey } from "features/doctypesSlice";

interface PdfViewerContextProps {
  pdfViewer: PdfViewerComponent | null;
  setPdfViewer: (viewer: PdfViewerComponent | null) => void;
  loadPdfByBlob: (fileBlob: Blob, fileName: string) => void;
  loadPdfByBase64: (fileBase64: string, fileName: string) => void;
  closePdf: (fileName: string) => void;
  addAnnotation: (annotationType: AnnotationType, options: any) => void;
  removeAnnotation: (annotationId: string) => void;
  goToPage: (page: number) => void;
  loadFromIndexedDb: () => Promise<void>;
  updateAnnotationStatus: () => void;
  annotationChanged: boolean;
}

export class ErrorPasswordProtection extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ErrorPasswordProtection";
  }
}

export class ErrorImportPDF extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ErrorImportPDF";
  }
}

const PdfViewerContext = createContext<PdfViewerContextProps | undefined>(
  undefined
);

export const usePdfViewer = (): PdfViewerContextProps => {
  const context = useContext(PdfViewerContext);
  if (!context) {
    throw new Error("usePdfViewer must be used within a PdfViewerProvider");
  }
  return context;
};

export const PdfViewerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pdfViewer, setPdfViewer] = useState<PdfViewerComponent | null>(null);
  const [annotationChanged, setAnnotationChanged] = useState(false);
  const dispatch = useDispatch();
  const pdfFileURI = useSelector(
    (state: RootState) => state.appState.pdfFileURI
  ); // Use useSelector to access Redux store

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement: any = document.activeElement;

      if (event.ctrlKey) {
        switch (event.key) {
          case "ArrowUp":
          case "ArrowDown":
            if (
              pdfViewer &&
              (!activeElement || activeElement.tagName !== "INPUT" || activeElement.tagName !== "SELECT" || activeElement.tagName !== "LI")
            ) {
              if (event.key === "ArrowUp") {
                pdfViewer.navigation.goToPreviousPage();
              } else if (event.key === "ArrowDown") {
                pdfViewer.navigation.goToNextPage();
              }
              event.preventDefault();
            }
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pdfViewer]);

  const checkIfPdfIsEncrypted = async (buffer: ArrayBuffer): Promise<boolean> => {
    try {
      const pdf = await PDFDocument.load(buffer, { ignoreEncryption: true });
      return pdf.isEncrypted;
    } catch (error: any) {
      if (error.message.startsWith("Failed to parse PDF document")) {
        throw new ErrorImportPDF(error.message)
      } else {
        throw error
      }
    }
    return false
  };

  const loadPdfByBlob = async (fileBlob: Blob, fileName: string) => {
    const buffer = await fileBlob.arrayBuffer();
    const isEncrypted = await checkIfPdfIsEncrypted(buffer);
    if (isEncrypted) {
      dispatch(setStatus(AppStatus.NO_DOCUMENT));
      throw new ErrorPasswordProtection("PDF is password protected.");
    }

    const fileBase64 = await blobToBase64(fileBlob);
    loadPdf(fileBase64, fileName);
  };

  const loadPdfByBase64 = async (fileBase64: string, fileName: string) => {
    const fileBlob = base64ToBlob(fileBase64);
    const buffer = await fileBlob.arrayBuffer();
    const isEncrypted = await checkIfPdfIsEncrypted(buffer);
    if (isEncrypted) {
      throw new ErrorPasswordProtection("PDF is password protected.");
    }

    loadPdf(fileBase64, fileName);
  };

  const loadPdf = async (fileBase64: string, fileName: string) => {
    if (!pdfViewer) {
      console.error("PDF Viewer is not initialized yet.");
      return;
    }

    dispatch(setStatus(AppStatus.LOADING));

    try {
      pdfViewer.load(fileBase64, "");
      dispatch(setPdfFileURI(fileName));
      dispatch(setStatus(AppStatus.LOADED));
    } catch (error: any) {
      console.error(error);
      dispatch(setPdfFileURI(""));
      dispatch(setStatus(AppStatus.NO_DOCUMENT));
    }
  };

  const updateAnnotationStatus = () => {
    setAnnotationChanged((prev) => !prev);
  };

  const addAnnotation = async (
    annotationType: AnnotationType,
    options: any
  ) => {
    if (!pdfViewer) {
      console.error("PDF Viewer is not initialized yet.");
      return;
    }
    options.customStamps[0].customStampName = `${options.customStamps[0].customStampName}`;
    pdfViewer.annotation.addAnnotation(annotationType, options);
    dispatch(setStatus(AppStatus.EDITING));
    updateAnnotationStatus();
  };

  const removeAnnotation = async (annotationId: string) => {
    if (!pdfViewer) {
      console.error("PDF Viewer is not initialized yet.");
      return;
    }
    pdfViewer.annotation.deleteAnnotationById(annotationId);
    dispatch(setStatus(AppStatus.EDITING));
    updateAnnotationStatus();
  };

  const goToPage = (page: number) => {
    if (!pdfViewer) return;
    pdfViewer.navigation.goToPage(page);
  };

  const loadFromIndexedDb = async () => {
    if (!pdfViewer) {
      console.error("PDF Viewer is not initialized yet.");
      return;
    }
    try {
      const { blob } = await getPDFFromDB();
      if (blob) {
        await loadPdfByBlob(blob, "AutoSaved.pdf");
      }
    } catch (error) {
      console.error("Failed to load from IndexedDB:", error);
    }
  };

  const closePdf = async (fileName: string) => {
    if (!pdfViewer) return;
    dispatch(setStatus(AppStatus.NO_DOCUMENT));
    try {
      const formData = new FormData();
      formData.append("FileName", fileName);
      await api.post("/pdffile/unlock", formData);
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(setPdfFileURI(""));
      dispatch(setStatus(AppStatus.NO_DOCUMENT));
    }
    dispatch(setSelectedDoctypeKey(null));
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const base64ToBlob = (base64: string): Blob => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: "application/pdf" });
  };

  return (
    <PdfViewerContext.Provider
      value={{
        pdfViewer,
        setPdfViewer,
        loadPdfByBlob,
        loadPdfByBase64,
        addAnnotation,
        removeAnnotation,
        goToPage,
        loadFromIndexedDb,
        closePdf,
        updateAnnotationStatus,
        annotationChanged,
      }}
    >
      {children}
    </PdfViewerContext.Provider>
  );
};
