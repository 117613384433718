import React, { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { AppStatus, setStatus } from "features/appStateSlice";
import { useDialog } from "context/DialogContext";
import { ErrorImportPDF, ErrorPasswordProtection, usePdfViewer } from "context/PdfViewerContext";
import imageAddpdf from "assets/images/ADDPDF.png";

const DragAndDropFileImport: FC = () => {
  const { t } = useTranslation();
  const [displayTool, setDisplayTool] = useState(false);
  const [isPdfViewerInitialized, setPdfViewerInitialized] = useState(false); // New state for pdfViewer initialization
  const status = useSelector((state: RootState) => state.appState.status);
  const dispatch = useDispatch();
  const { showDialog, forceCloseDialog } = useDialog();
  const { pdfViewer, loadPdfByBlob } = usePdfViewer();

  useEffect(() => {
    setDisplayTool(status !== AppStatus.NO_DOCUMENT);
  }, [status]);

  useEffect(() => {
    if (pdfViewer) {
      setPdfViewerInitialized(true); // Set pdfViewer initialized state to true when pdfViewer is ready
    }
  }, [pdfViewer]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!isPdfViewerInitialized) return; // Prevent dropping files if pdfViewer is not initialized

      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];

        if (!pdfViewer) return;

        const selectedFile = file;

        if (!selectedFile || !selectedFile.name.toLowerCase().endsWith(".pdf")) {
          showDialog({
            type: "base",
            title: t("modal.errorOccurred"),
            message: t("modal.notPdfFile"),
            severity: "error",
          });
          return;
        }

        if (status === AppStatus.EDITING) {
          const confirm = await showDialog({
            type: "confirmation",
            title: "Confirmation",
            message: t("promptMessage"),
            severity: "warning",
          });
          if (!confirm) return;
        }

        try {
          showDialog({
            type: "lock",
            title: t("headerMsg.loading"),
            message: t("headerMsg.importing"),
            severity: "info",
            progress: true,
          });

          dispatch(setStatus(AppStatus.LOADING));

          await loadPdfByBlob(selectedFile, selectedFile.name);
        } catch (error: any) {
          if (error instanceof ErrorPasswordProtection) {
            // Handle password-protected PDF error
            showDialog({
              type: "base",
              title: t("modal.errorOccurred"),
              message: t("modal.errorPasswordProtection"),
              severity: "error",
            });
          } else if (error instanceof ErrorImportPDF) {
            // Handle password-protected PDF error
            showDialog({
              type: "base",
              title: t("modal.errorOccurred"),
              message: t("modal.errorImportPDF"),
              severity: "error",
            });
          } else {
            showDialog({
              type: "base",
              title: t("modal.errorOccurred"),
              message: t("error.trySendingAgain"),
              severity: "error",
            });
          }
        }
      }
    },
    [isPdfViewerInitialized, pdfViewer, dispatch, showDialog, loadPdfByBlob, status, t]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    noClick: true, // Disable click to select files
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: isDragActive ? "2px dashed #aaa" : "",
        borderRadius: isDragActive ? "8px" : "",
        padding: isDragActive ? "16px" : "",
        textAlign: "center",
        cursor: isDragActive ? "pointer" : "",
        width: "100%",
        height: "100%",
        backgroundColor: isDragActive ? "#cccccc" : "transparent",
      }}
      style={{ visibility: !displayTool ? "visible" : "hidden" }}
    >
      <input
        {...getInputProps({
          onClick: (e) => e.preventDefault(), // Prevent default click action
        })}
      />
      {isDragActive && (
        <img
          src={imageAddpdf}
          alt="PDF"
          style={{ width: "50px", height: "50px" }}
        />
      )}
    </Box>
  );
};

export default DragAndDropFileImport;
