import "./SelectedTool.css";
import { useEffect, useState } from "react";
import BarcodeTool from "../BarcodeTool/BarcodeTool";
import { useSelector } from "react-redux";
import { RootState } from "services/store";
import { Box } from "@mui/material";
import { AppStatus } from "features/appStateSlice";
import { FocusProvider } from "context/FocusContext";

const SelectedTool: React.FC = () => {
  const status = useSelector((state: RootState) => state.appState.status);

  const [displayTool, setDisplayTool] = useState(false);

  useEffect(() => {
    setDisplayTool(status !== AppStatus.NO_DOCUMENT && status !== AppStatus.LOADING);
  }, [status]);

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        px: 4,
        pt: 2,
        pb: 4,
        background: "#366193",
      }}
    >
      <FocusProvider>{displayTool && <BarcodeTool />}</FocusProvider>
    </Box>
  );
};

export default SelectedTool;
