import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="75%"
    >
      <Container sx={{ textAlign: "center" }} >
        <Typography variant="h3" component="h1" gutterBottom color={"white"} sx={{mb:8}}>
          {t("notFound.title")}
        </Typography>
        <Typography variant="body1" gutterBottom color={"white"} sx={{maxWidth:"352px", marginX:"auto"}}>
          {t("notFound.message")}
        </Typography>
      </Container>
    </Box>
  );
};

export default NotFoundPage;
